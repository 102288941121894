/* 移动端适配样式 */
@media (max-width: 768px) {
  .mobile-register-container {
    padding: 0 15px;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-adaptive-container {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    margin-top: 10px !important;
  }

  .register-form input {
    font-size: 16px !important;
    height: 48px !important;
    padding: 12px 15px !important;
  }

  .verify-code-container {
    flex-direction: column !important;
  }

  .send-code-btn {
    width: 100% !important;
    margin-top: 12px !important;
    height: 48px !important;
    font-size: 14px !important;
  }

  .register-button {
    height: 48px !important;
    font-size: 16px !important;
  }

  .form-group {
    margin-bottom: 20px !important;
  }

  .mobile-safe-area {
    height: env(safe-area-inset-bottom);
  }

  /* 国家选择器适配 */
  .country-selector {
    width: 100% !important;
    margin-bottom: 12px !important;
  }

  /* 成功页面适配 */
  .success-container {
    padding: 20px !important;
  }

  .success-icon {
    width: 80px !important;
    height: 80px !important;
  }
}