.sidebar_product_recommendation_title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar_layout {
  display: flex;
  justify-content: space-between;
  padding: 0 10px
}

.picture_small {
  display: flex;
  margin-top: 12px;
  max-width: 380px;
  overflow: auto;
}

.picture_small::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.picture_small>img {
  width: 100px;
  height: 100px;
  background-color: #F2F2F2;
  margin: 0 3px;
}


.selectImg {
  border: 2px solid red;
  border-radius: 10px;
}

.noSelectImg {
  border: 2px solid #fff;
  border-radius: 10px;
}

.related_products_list {
  display: flex;
  flex-direction: column;
  height: 300px;
}

.related_products_list>div {
  display: flex;
  margin-top: 10px;
}

.related_products {
  height: 320px;
  overflow-y: auto;
}

.related_products::-webkit-scrollbar {
  width: 3px;
}

.related_products::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.related_products::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.related_products_info {
  cursor: pointer;
  width: 110px;
}

.related_products_info>div {
  margin: 10px;
}

.related_products_img {
  width: 180px;
  height: 180px;
}

.related_products_img>img {
  width: 180px;
  height: 180px;
}


.related_products_name {
  font-size: 16px;
  width: 160px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.RelatedProductsStyle {
  width: 200px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.goodsName_style {
  text-align: left;
}