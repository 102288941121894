.responsive-footer {
  background: #f8f9fa;
  padding: 2rem 1rem;
  margin-top: auto;
  /* 确保页脚置底 */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.copyright-info,
.company-name {
  color: #666;
  margin: 0.5rem 0;
  line-height: 1.6;
}

/* 桌面端样式 */
@media (min-width: 768px) {
  .responsive-footer {
    padding: 2rem 3rem;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }

  .copyright-info {
    flex: 2;
    padding-right: 2rem;
  }

  .company-name {
    flex: 1;
    text-align: right;
    white-space: nowrap;
  }
}

/* 移动端样式 */
@media (max-width: 767px) {
  .responsive-footer {
    padding: 1.5rem 1rem;
  }

  .copyright-info {
    font-size: 0.875rem;
  }

  .company-name {
    font-size: 0.9rem;
    margin-top: 1rem;
    display: block;
  }
}

/* 小屏手机优化 */
@media (max-width: 480px) {
  .copyright-info {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .company-name {
    font-size: 0.85rem;
  }
}