.registration_successful {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}


.registration_successful_scope {
  width: 100%;
  height: 171px;
  padding: 20px;
  border: 1px solid #f2f2f2;
}

.registration_successful_icon {
  font-size: 36px;
  display: flex;
}

.registration_successful_icon>div {
  font-size: 20px;
  margin-left: 20px;
}

.registration_successful_tips {
  font-size: 14px;
  margin-left: 56px;
  color: rgba(0, 0, 0, 0.45);

}

.registration_successful_keepShopping {
  margin-top: 20px;
  margin-left: 56px;
  width: 140px;
  height: 50px;
  background: #fff;
  line-height: 50px;
  text-align: center;
  border: 1px solid #EE1D23;
  color: #EE1D23;
}

.registration_successful_keepShopping:hover {
  margin-top: 20px;
  margin-left: 56px;
  width: 140px;
  height: 50px;
  background: #EE1D23;
  line-height: 50px;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
}

.registration_successful_viewOrder {
  margin-top: 20px;
  margin-left: 20px;
  width: 140px;
  height: 50px;
  background: #EE1D23;
  line-height: 50px;
  text-align: center;
  border: 1px solid #EE1D23;
  color: #fff;
}

.registration_successful_viewOrder:hover {
  margin-top: 20px;
  margin-left: 20px;
  width: 140px;
  height: 50px;
  background: #fff;
  line-height: 50px;
  text-align: center;
  border: 1px solid #EE1D23;
  color: #EE1D23;
}

.registration_successful {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}


.registration_successful_scope {
  width: 100%;
  height: 171px;
  padding: 20px;
  border: 1px solid #f2f2f2;
}

.registration_successful_icon {
  font-size: 36px;
  display: flex;
}

.registration_successful_icon>div {
  font-size: 20px;
  margin-left: 20px;
}

.registration_successful_tips {
  font-size: 14px;
  margin-left: 56px;
  color: rgba(0, 0, 0, 0.45);

}


.product_recommendation {
  display: flex;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-top: 40px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.product_recommendation>div {
  /* display: flex;
  justify-content: space-between;
  width: 390px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2; */
}

.product_recommendation_title {
  display: flex;
  justify-content: space-between;
  width: 390px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2;
}

.product_recommendation_img {
  width: 80px;
  height: 80px;
}

.product_recommendation_img>img {
  width: 80px;
  height: 80px;
}

.product_recommendation_list {
  display: flex;
  flex-direction: column;
  height: 294px;
  animation-timing-function: ease;
}

.product_recommendation_list>div {
  display: flex;
  margin-top: 16px;
}

.product_recommendation_info {
  cursor: pointer;
  position: relative;
  /* animation-name: ball;
  animation-duration: 2s; */
}

.product_recommendation_info>div {
  margin-right: 10px;
}

@keyframes ball {
  0% {
    left: 0px;
  }

  50% {
    left: 200px;
  }

  100% {
    left: 0px;
  }
}

.product_recommendation_name {
  font-size: 16px;
}


.order_success_btn {
  display: flex;
}