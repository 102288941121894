.region {
  font-size: 12px;
  width: 120px;
  height: 50px;
  border: 1px solid #f2f2f2;
  line-height: 50px;
}

.phone_inputs {
  flex-grow: 8;
  margin-left: 2%;
  float: right;
  display: inline-block;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
}

.fname_inputs {
  width: 100%;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
}

.inputCaptcha {
  flex-grow: 8;
  float: right;
  display: inline-block;
}

.region_select {
  display: flex;
  align-items: stretch;
}

.buttonCaptcha {
  margin-left: 2%;
  font-size: 12px;
  padding: 15px 25px;
  margin-bottom: 30px;
  height: inherit;
}

.container2 {
  margin-top: 10px;
}

.login_title_sty {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px;
}

.login_sty {
  padding: 30px;
  border: 1px solid #f2f2f2;
  line-height: 1;
}

.login_form_group {
  margin: 20px 0;
}

.login_form_group>div {
  margin: 20px 0;
}

.password_inputs {
  width: 100%;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
}

.login_form_group>.login_form_group_title {
  display: flex;
  justify-content: space-between;
}

.login_form_group>.login_form_group_title {
  display: flex;
  justify-content: space-between;
}


.login_form_group>.login_form_group_title>.forgotten_password {
  color: rgba(0, 0, 0, 0.45);
}

.login_btn {
  width: 100px;
  height: 50px;
  color: #2e3092;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #2e3092;
}

.login_btn:hover {
  width: 100px;
  height: 50px;
  color: #fff;
  background-color: #2e3092;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #000;
}

.remember_me_sty>div>input {
  border: 1px solid #f2f2f2;
  margin-right: 8px;
}

.remember_me_sty>div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;

}

.login_form_group>.remember_me_sty {
  display: flex;

}

.remember_me_tips_sty {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.login_sty>p {
  width: 320px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.captcha_sty {
  display: flex;
}

.captcha_btn {
  width: 100px;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
  background-color: #F2F2F2;
  color: #2E3092;
  line-height: 50px;
  text-align: center;
}

.captcha_btn:hover {
  width: 100px;
  height: 50px;
  border: 1px solid #000;
  padding: 0 10px;
  background-color: #2E3092;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.captcha_inputs1 {
  width: 60%;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
}

.captcha_btn_time {
  width: 100px;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
  background-color: #F2F2F2;
  color: #2E3092;
  line-height: 50px;
  text-align: center;
}

.create_account_btn {
  width: 100px;
  height: 50px;
  border: 1px solid #f2f2f2;
  padding: 0 10px;
  background-color: #F2F2F2;
  color: #2E3092;
  line-height: 50px;
  text-align: center;
}

.push {
  height: 60px;
}

.registration_successful {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}


.registration_successful_scope {
  width: 800px;
  height: 171px;
  padding: 20px;
  border: 1px solid #f2f2f2;
}

.registration_successful_icon {
  font-size: 36px;
  display: flex;
}

.registration_successful_icon>div {
  font-size: 20px;
  margin-left: 20px;
}

.registration_successful_tips {
  font-size: 14px;
  margin-left: 56px;
  color: rgba(0, 0, 0, 0.45);

}

.registration_successful_login {
  margin-top: 20px;
  margin-left: 56px;
  width: 140px;
  height: 50px;
  background: #f2f2f2;
  line-height: 50px;
  text-align: center;
  border: 1px solid #2e3092;
  color: #2e3092;
}

.registration_successful_login:hover {
  margin-top: 20px;
  margin-left: 56px;
  width: 140px;
  height: 50px;
  background: #2e3092;
  line-height: 50px;
  text-align: center;
  border: 1px solid #000;
  color: #fff;
}

.product_recommendation {
  display: flex;
  justify-content: space-between;
  display: flex;
  width: 800px;
  margin-top: 40px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.product_recommendation>div {
  /* display: flex;
  justify-content: space-between;
  width: 390px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2; */
}

.product_recommendation_title {
  display: flex;
  justify-content: space-between;
  width: 390px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2;
}

.product_recommendation_img {
  width: 80px;
  height: 80px;
}

.product_recommendation_img>img {
  width: 80px;
  height: 80px;
}

.product_recommendation_list {
  display: flex;
  flex-direction: column;
  height: 294px;
  animation-timing-function: ease;
}

.product_recommendation_list>div {
  display: flex;
  margin-top: 16px;
}

.product_recommendation_info {
  cursor: pointer;
  position: relative;
  /* animation-name: ball;
  animation-duration: 2s; */
}

.product_recommendation_info>div {
  margin-right: 10px;
}

@keyframes ball {
  0% {
    left: 0px;
  }

  50% {
    left: 200px;
  }

  100% {
    left: 0px;
  }
}

.product_recommendation_name {
  font-size: 16px;
}

.about-us {
  margin: 10px 0;
  width: 100%;
}

/* login.css */
.container {
  width: 100%;
  /* 确保容器宽度占满屏幕 */
  padding: 0 16px;
  /* 增加内边距以适应小屏幕 */
}

.captcha_inputs,
.phone_inputs,
.password_inputs {
  width: calc(100% - 32px);
  /* 输入框宽度自适应，留出内边距空间 */
  padding: 10px;
  /* 增加内边距以提高可点击区域 */
  font-size: 16px;
  /* 适当调整字体大小 */
}

/* 针对小屏幕的优化 */
@media (max-width: 768px) {
  .login_form_group {
    margin-bottom: 16px;
    /* 增加垂直间距以提高可读性 */
  }
}

/* 同时解决溢出+适配多端 */
.login_sty {
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}

.login_sty p {
  word-wrap: break-word;
  max-width: 100%;
  margin: 0 auto;
  font-size: clamp(12px, 3vw, 14px);
  /* 动态字号 */
}