// 响应式容器
.product-list-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

// 布局结构
.responsive-layout {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

// 树形导航适配
.tree-navigation {
  position: sticky;
  top: 20px;
  height: fit-content;

  @media (max-width: 768px) {
    display: none; // 移动端隐藏树形导航
  }

  .ant-tree {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
  }
}

// 主内容区域
.product-main {
  min-height: 80vh;
}

// 产品网格布局
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

// 空状态样式
.empty-state {
  text-align: center;
  padding: 40px 0;

  .empty-image {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    color: #666;
    margin-bottom: 20px;
  }
}

// 分页组件适配
.responsive-pagination {

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 32px;
    height: 32px;
    line-height: 32px;
  }

  @media (max-width: 480px) {
    .ant-pagination-item {
      display: none;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: inline-flex;
    }
  }
}

.prodItem {
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
}

// 移动端菜单按钮
.mobile-filter-btn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    display: block;
  }
}