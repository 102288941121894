.search_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search_title>div {}

.product_list_division_sty {
  display: flex;
  flex-direction: column;
}

.product_list_division_sty>div {
  display: flex;
  justify-content: space-between;
}


:where(.css-dev-only-do-not-override-t9vmwr).ant-pagination .ant-pagination-options {
  float: right;
}

.pagination_sty {
  margin-top: 20px;
}

.tabs_info_sty {
  width: 796px;
}

.tree_son_sty {
  width: 240px;
  margin-right: 20px;
  font-size: 18px;
}

.css-var-.ant-tree {
  --ant-tree-node-selected-bg: #1890ff !important;
}

.tree_sty {
  border: 1px solid #f2f2f2;
  padding: 10px 0;
  height: 100%;

  .ant-tree-node-content-wrapper:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  /* 清除选中装饰层 */
  .ant-tree-treenode-selected::after {
    opacity: 0 !important;
  }

  /* 强制覆盖文字外围样式 */
  .ant-tree-treenode-selected .ant-tree-title {
    text-shadow: none !important;
    background-image: none !important;
  }

  /* 清除连接线干扰 */
  .ant-tree-switcher-noop {
    display: none !important;
  }
}


.tree_sty .ant-tree-treenode-selected {
  background-color: #1890ff !important;
}


.tree_sty .ant-tree-treenode-selected .ant-tree-title {
  color: white !important;
  background-color: #1890ff !important;
}

.tree_sty .ant-tree-treenode:hover {
  background-color: #40a9ff !important;
}

.tree_sty .ant-tree-treenode:hover .ant-tree-title {
  color: white !important;
}

.product_list_spin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  width: 100%
}


.search_input {
  width: 250px;
  margin-right: 20px;
}