// 响应式容器
.product-list-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

// 搜索区域适配
.search-container {
  display: grid;
  gap: 20px;
  margin: 30px 0;

  .search-fields {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }

  .button-group {
    display: flex;
    gap: 10px;
    grid-column: 1 / -1;
  }

  .sort-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

// 响应式标签页
.responsive-tabs {
  .ant-tabs-nav {
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// 产品列表布局
.product-list-wrapper {
  margin-top: 30px;

  .responsive-products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
  }
}

// 移动端适配
@media (max-width: 768px) {
  .product-list-container {
    padding: 0 10px;
  }

  .search-container {
    .search-fields {
      grid-template-columns: 1fr;
    }

    .sort-container {
      flex-direction: column;
      align-items: stretch;

      .sort-label {
        text-align: center;
      }
    }
  }

  .product-list-wrapper {
    .responsive-products {
      grid-template-columns: 1fr;
    }
  }

  .ant-tabs-tab {
    padding: 12px 8px !important;
    font-size: 14px;
  }
}

// 平板适配
@media (min-width: 769px) and (max-width: 1024px) {
  .product-list-wrapper {
    .responsive-products {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}