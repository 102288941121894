.sidebar_product_recommendation_title {
  display: flex;
  justify-content: space-between;
  width: 260px;
  padding: 13px 10px;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar_layout {
  display: flex;
  justify-content: space-between;
  padding: 0 15px
}

.picture_small {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 10px;
  width: 100%;
  overflow: auto;
}

.picture_small>img {
  width: 100px;
  height: 100px;
  background-color: #F2F2F2;
  margin: 0 3px;
}


.selectImg {
  border: 2px solid red;
  border-radius: 10px;
}

.noSelectImg {
  border: 2px solid #fff;
  border-radius: 10px;
}

.related_products_list {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  max-width: 100%;
  max-height: 360px;
  animation-timing-function: ease;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}


.related_products_list>div {
  display: flex;
  margin-top: 16px;
}

.related_products_info {
  max-width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
}

.related_products_info>div {
  margin-right: 5px;
}

.related_products_img {
  width: 100px;
  height: 100px;
}

.related_products_img>img {
  width: 100px;
  height: 100px;
}


.related_products_name {
  font-size: 16px;
  width: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.RelatedProductsStyle {
  width: 100%;
  padding: 5px;
  overflow-x: auto;
}

.goodsName_style1 {
  width: 100%;
  overflow-wrap: break-word;
  text-align: left;
}