.menu-right {
  margin-left: auto;
}

.header_right_logo {
  display: flex;
  gap: 1rem;
  align-items: center;

  // 通用按钮样式
  .account-btn,
  .cart-btn {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    transition: all 0.3s ease;
    min-height: 44px; // 最小触摸尺寸

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &.selected {
      background: #2e3092;
      color: white;
    }
  }

  // 图标样式
  .btn-icon {
    font-size: 1.5rem;
    flex-shrink: 0;
  }

  // 文字标签
  .btn-label {
    margin: 0 0.5rem;
    white-space: nowrap;
  }

  // 购物车徽章适配
  .cart-badge {
    .ant-badge-count {
      box-shadow: 0 0 0 1px #fff;
    }
  }

  // 移动端适配
  @media (max-width: 768px) {
    gap: 0.5rem;

    .btn-label {
      display: none; // 移动端隐藏文字
    }

    .btn-icon {
      font-size: 1.2rem;
      padding: 6px;
    }

    .cart-badge {
      .ant-badge-count {
        font-size: 10px;
        height: 16px;
        min-width: 16px;
        line-height: 16px;
      }
    }
  }

  // 平板适配
  @media (min-width: 769px) and (max-width: 1024px) {
    .btn-label {
      font-size: 0.9rem;
    }

    .btn-icon {
      font-size: 1.3rem;
    }
  }
}