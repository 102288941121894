.layout-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.content-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .content-wrapper {
    padding: 15px;
  }
}

.responsive-img {
  max-width: 100%;
  height: auto;
  display: block;
}