/* 确保页面元素适应手机端屏幕 */
.container {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.personalCentre_tabs {
  display: flex;
  padding: 5px;
  flex-direction: column;
}

.personal_logOut1 {
  border: 1px solid #2E3092;

}

.personal_logOut1>div {
  height: 50px;
  line-height: 30px;
  padding: 10px;
  text-align: center;
  /* border-left: 1px dashed #2E3092; */
}


.font-color-blue {
  font-size: 14px;
}

/* 在移动端强制横向滚动 */
@media (max-width: 992px) {


  /* 滚动容器 */
  .mobile-tabs-wrapper .ant-tabs-nav-wrap {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    /* Firefox隐藏滚动条 */
    padding-bottom: 5px;
    max-width: 100%;
    /* 滚动条显示空间 */
  }

  /* Chrome/Safari隐藏滚动条 */
  .mobile-tabs-wrapper .ant-tabs-nav-wrap::-webkit-scrollbar {
    display: none;
  }

  /* 标签项样式 */
  .mobile-tabs-wrapper .ant-tabs-tab {
    white-space: nowrap;
    padding: 12px 20px !important;
    flex-shrink: 0;
    /* 禁止标签缩小 */
  }

  /* 导航栏容器 */
  .mobile-tabs-wrapper .ant-tabs-nav {
    min-width: max-content;
    margin: 0 !important;
  }

  /* 滚动提示效果 */
  .scroll-indicator {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
    pointer-events: none;
    z-index: 1;
  }
}

.personalCentre_tabs {
  max-width: 100vw;
  overflow: hidden;
}

.tabsItem1 {}

.my_orders {
  margin-top: 20px;
  width: 100%;
}

.sctable {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 60px;
  -webkit-overflow-scrolling: touch;
  /* 最小宽度需匹配scroll.x的值 */
}

.myOrders_search {
  display: flex;
}

.myOrders_input {
  display: flex;
}

.no_news_yet {
  width: 100%;
  height: 200px;
  border: 1px solid #F2F2F2;
  margin: 0 auto;
  padding: 5px;
  margin-top: 20px;
}

.registration_successful_backHome {
  display: inline-block;
  margin-top: 20px;
  margin: 5px;
  width: 140px;
  height: 50px;
  background: #fff;
  line-height: 50px;
  text-align: center;
  border: 1px solid #2E3092;
  color: #2E3092;
}

.registration_successful_backHome:hover {
  display: inline-block;
  margin-top: 20px;
  margin: 5px;
  width: 140px;
  height: 50px;
  background: #2E3092;
  line-height: 50px;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
}

.registration_successful_commodityCentre {
  display: inline-block;
  margin-top: 20px;
  margin: 5px;
  width: 140px;
  height: 50px;
  background: #2E3092;
  line-height: 50px;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
}

.registration_successful_commodityCentre:hover {
  display: inline-block;
  margin-top: 20px;
  margin: 5px;
  width: 140px;
  height: 50px;
  background: #fff;
  line-height: 50px;
  text-align: center;
  border: 1px solid #2E3092;
  color: #2E3092;
}

.font-size-20 {
  font-size: 20px;
}

.no_news_yet_title {
  display: flex;
  align-items: center;
}

.no_news_yet_title>div {
  margin-left: 20px;
}

.no_news_yet_context {
  margin-left: 55px;
  color: rgba(0, 0, 0, 0.45);
}

.personalCentre_tabs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  min-width: 900;
  justify-content: space-between;
}

.personal_logOut {
  border: 1px solid #2E3092;
  display: flex;
  align-items: center;
}

.personal_logOut>div {
  height: 50px;
  line-height: 30px;
  padding: 10px;
  /* border-left: 1px dashed #2E3092; */
}

.personal_logOut>div>span {
  margin-left: 10px;
}

.font-color-blue {
  color: #2E3092;
  cursor: pointer;
}


.myOrders_search {
  display: flex;
}

.myOrders_input {
  display: flex;
}

.loading_myOrder {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip_flop_footer {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img_sty {
  width: 80px;
  height: 80px;
}

.purchasedGoods_produceName_sty:hover {
  color: red;
}

.purchasedGoods_operation_sty {
  display: flex;
  flex-direction: column;
  color: red;
  cursor: pointer;
  text-align: right;
}

.purchasedGoods_operation_sty>div {
  margin-right: 10px;
}

.order_info {
  display: flex;
  justify-content: space-around;
}

.order_info>div {
  width: 490px;
}

.prod_order_info {
  display: flex;
  justify-content: space-around;
}

.prod_order_info>div {
  width: 490px;
}

.order_detail_border {
  border: 1px solid #f2f2f2;
  margin: 10px 0;
}

.order_detail_border>div {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.order_detail_border>div>div {
  display: flex;
  align-items: center;
}

.order_detail_border>div>div>div {
  margin-right: 10px;
}

.font-size-16 {
  font-size: 16px;
}

.color-grey {
  color: rgba(0, 0, 0, 0.45);
}

.color-red {
  color: #EE1D23
}

.color-blue {
  color: #2E3092
}

.background-color-grey {
  background-color: #f2f2f2;
}

.conclude_sty {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}

.conclude_sty>div {
  display: flex;
  align-items: center;
}

.conclude_sty>div>div {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.conclude_sty>div>div>div {
  margin-left: 15px;
}


.number_goods {
  width: 200px;
}

.no_shipping_address_sty {
  width: 800px;
  height: 102px;
  border: 1px solid #F2F2F2;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.personalCentre_deliveryAddress_sty_center {
  display: flex;
  flex-direction: column;
  /* 始终垂直排列 */
  width: 100%;
  margin: 66px 0;
}


.personalCentre_accountInfo_sty {
  display: flex;
  flex-direction: column;
  padding: 5px;
  /* flex-direction: column; */
  width: 100%;
  margin: 20px 0;

}

.personalCentre_accountInfo_sty>div {
  border: 1px solid #F2F2F2;
  width: 100%;
  margin: 0 10px;
  padding: 20px;
}

.personalCentre_accountInfo_sty>div>div {

  margin-bottom: 20px;

}

.new_address_sty {
  width: 100%;
  border: 1px solid #F2F2F2;
  padding: 2px;
}

.new_address_sty_noWidth {
  border: 1px solid #F2F2F2;
  padding: 2px;
}

.my_orders_address_width {
  width: 100%;
  margin: 5px;
}

.myOrder_delivery_address_sty {
  display: flex;
}

.form_deliveryAddressList_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
  border: 1px solid #F2F2F2;
  margin-bottom: 10px;
  padding: 20px;
}

.form_deliveryAddressList_info_blue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  border: 1px solid #2E3092;
  margin-bottom: 10px;
  padding: 20px;
}

.default_address_sty {
  width: 44px;
  height: 24px;
  background: #2e3092;
  border-radius: 8px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 24px;

}

.myOrder_deliveryAddress_sty {
  color: rgba(0, 0, 0, 0.45);
}


.my_orders_spin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.OrderPayButton {
  background-color: #2E3092;
  color: #fff;
  right: 0;
  width: 100px;
  padding: 0 10px;
}

.OrderPosition {
  position: relative;
}

.OrderPayStatus {
  margin-right: 50px;
}



.query_input {
  padding: 4px 15px;
}

.payment_input {
  padding: 4px
}


.order_detail_total_sty {
  text-align: right;
}

.order_detail_total_sty>div {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.order_detail_total_sty>div>div {
  margin-left: 24px;
}

.order_detail_total_sty>div>div>span {
  margin-left: 16px;
}

.order_detail_total_sty>div>div>span>span {
  margin-right: 6px;
}


.operation_button_style>div {
  width: 140px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.operation_button_style {
  width: 100%;
  justify-content: end;
}

.radio_ddressList_style {
  max-height: 400px;
  overflow-x: hidden;
}


.specialPrice {
  color: #EE1D23;
  font-size: 24px;
}

.specialPrice>div {
  font-size: 14px;
  text-align: right;
}

.remark_style {
  width: 100%;

}

.mobile-tabs-wrapper {
  position: relative;
  overflow: hidden;
}